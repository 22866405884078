.FsnModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 2;
}

.FsnModal .content {
  background-color: black;
  padding: 16px 16px 24px 16px;
  border-radius: 8px;
  max-width: 328px;
  margin-top: 20%;
}

.FsnModal .content .title {
  font-size: 20px;
}

.FsnModal .content .text {
  font-size: 14px;
}

.FsnModal .closeBtn {
  margin-top: 10px;
  width: 100%;
}
