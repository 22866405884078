.types,
.floors,
.search,
.info,
.floorInfo {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* .floors-appear {
  display: flex;
}

.floors-appear-active {
  display: flex;
} */

.floors-enter,
.types-enter,
.info-enter,
.floorInfo-enter,
.search-enter {
  opacity: 0.01;
  display: flex;
  /* transform: translateY(50%); */
  transform: translate3d(0, 50%, 0);
}
.floors-enter-active,
.types-enter-active,
.info-enter-active,
.floorInfo-enter-active,
.search-enter-active {
  opacity: 1;
  display: flex;
  /* transform: translateY(0%); */
  transform: translate3d(0, 0%, 0);
  transition: all 200ms ease-out;
}
.floors-enter-done,
.types-enter-done,
.info-enter-done,
.floorInfo-enter-done,
.search-enter-done {
  opacity: 1;
  display: flex;
  /* transform: translateY(0%); */
  transform: translate3d(0, 0%, 0);
  transition: all 200ms ease-out;
}
.floors-exit,
.types-exit,
.info-exit,
.floorInfo-exit,
.search-exit {
  opacity: 1;
  display: flex;
  /* transform: translateY(0%); */
  transform: translate3d(0, 0%, 0);
}

.floors-exit-active,
.types-exit-active,
.info-exit-active,
.floorInfo-exit-active,
.search-exit-active {
  opacity: 0.01;
  display: flex;
  /* transform: translateY(50%); */
  transform: translate3d(0, 50%, 0);
  transition: all 200ms ease-in;
}
.floors-exit-done,
.types-exit-done,
.info-exit-done,
.floorInfo-exit-done,
.search-exit-done {
  /* transform: translateY(100%); */
  transform: translate3d(0, 100%, 0);
}
