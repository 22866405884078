.red {
  background-color: #ff0000;
}

.yellow {
  background-color: #ffd700;
}

.green {
  background-color: #33ef04;
}
.pulsewrapper {
  width: 60px;
  height: 60px;
}

.pulse {
  height: 8px;
  width: 8px;
  top: 26px;
  left: 26px;
  border-radius: 100%;
  position: relative;
}

.ring {
  position: absolute;
  background-color: inherit;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  opacity: 1;
  animation: pulsing 2s ease-out infinite;

  &:nth-of-type(1) {
    animation-delay: -0.5s;
  }
  &:nth-of-type(2) {
    animation-delay: -1s;
  }
  &:nth-of-type(3) {
    animation-delay: -1.5s;
  }
}

@keyframes pulsing {
  100% {
    transform: scale(8);
    opacity: 0;
  }
}
