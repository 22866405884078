.buttons {
  margin: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 1;
}

.right {
  right: 2px;
}

.map {
  width: 100%;
  height: 100vh;
  background-color: #dcdbbc;
}

.info {
  z-index: 1;
  color: black;
  position: absolute;
  font: 12px/20px Helvetica Neue, Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 95px;
  width: 100%;
}
